import LoginPage from '@/components/LoginPage';
import Public from '@/Routes/Public';

const login = () => {
  return (
    <Public>
      <LoginPage />
    </Public>
  )
}

export default login