import Loading from "@/components/Loading";
import { useAuth } from "@/context/AuthContext";
import { useRouter } from "next/router";

const Public = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  if (isLoggedIn === null) {
    return <Loading />;
  } else if (isLoggedIn) {
    router.push("/");
    return null;
  }

  return children;
};

export default Public;
