import Head from "next/head";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from 'next/router';
import { useAuth } from "@/context/AuthContext";

const LoginPage = () => {
  const { TokenName, setToken, setCurrentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem(TokenName, data.token);
        setToken(data.token);
        setCurrentUser(data.user);
        router.push('/');
        toast.success('Login successful!');
      } else {
        const data = await response.json();
        toast.error(data.error || "Login failed.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        console.error("Login error:", error);
        toast.error("Failed to login. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Head>
        <title>Appran Glossary - Login</title>
        <meta
          name="description"
          content="Login to access our glossary website. Login to find definitions and information about various terms."
        />
        <meta
          name="keywords"
          content="login, glossary, definitions, terms, information"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="container"
      >
        <div className="d-flex justify-content-center">
          <div className="col-lg-6 col-12">
            <div className="loginCont">
              <motion.h4
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="textGradient fs-2 fw-bold text-center mb-3"
              >
                <Link href="/" className="link">
                  Login
                </Link>
              </motion.h4>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-4" controlId="formGroupEmail">
                  <Form.Label className="text-white fw-bold fs-6">
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="costField"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="formGroupPassword">
                  <Form.Label className="text-white fw-bold fs-6">
                    Password
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="costField"
                      required
                    />
                    <button
                      className="btn btn-outline-secondary border-0 rounded-0 passEye"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        style={{ height: "17px" }}
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </button>
                  </div>
                </Form.Group>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="d-grid gap-2 mt-2"
                >
                  <motion.button
                    whileHover={!loading ? { scale: 1.05 } : {}}
                    whileTap={!loading ? { scale: 0.9 } : {}}
                    type="submit"
                    className="btnStyles py-3 rounded-0"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Login"}
                  </motion.button>
                </motion.div>
              </Form>
              {/* <div className="fjbic text-white mt-4">
                <p>Don&apos;t have an Account</p>
                <p>
                  <Link href="/register" className="link">
                    {" "}
                    Register
                  </Link>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default LoginPage;
