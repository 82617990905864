import React from 'react';

const Loading = () => {
  return (
    <div className={`loading-overlay active`}>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default Loading;
